<template>
  <div>
    StudentAttendanceJournal
  </div>
</template>

<script>
export default {
  name: 'StudentAttendanceJournal',
  data(){
    return {

    }
  }
}
</script>

<style scoped>

</style>